import React from 'react';
import Dialog from 'seedly-component-library/dialog';

const TipsRow = props => {
  const { leftColumn, rightColumn } = props;
  return (
    <div className="flex items-center gap-4">
      <div style={{ width: 140 }}>{leftColumn}</div>
      {rightColumn}
    </div>
  );
};

const TipModal = props => {
  const { isOpen, setTipModalOpen } = props;

  return (
    <Dialog
      isOpen={isOpen}
      widthSize="xs"
      onCloseClick={() => setTipModalOpen(false)}
      headerTitle="Markdown Tips"
      isMobileFullHeight={false}
    >
      <div className="flex flex-col gap-3 w-full">
        <TipsRow
          leftColumn={<p className="text-sm font-bold">Type This</p>}
          rightColumn={<p className="text-sm font-bold">Get This</p>}
        />
        <div className="horizontal-divider" />
        <TipsRow
          leftColumn={<p>*italics*</p>}
          rightColumn={<p className="italic">italic</p>}
        />
        <div className="horizontal-divider" />
        <TipsRow
          leftColumn={<p>**bold**</p>}
          rightColumn={<p className="font-bold">bold</p>}
        />
        <div className="horizontal-divider" />
        <TipsRow
          leftColumn={
            <>
              <p>[insert link]</p>
              <p> (https://seedly.sg)</p>
            </>
          }
          rightColumn={<p className="text-blue-500 underline">Insert Link</p>}
        />
        <div className="horizontal-divider" />
        <TipsRow
          leftColumn={
            <>
              <p>- listing 1</p>
              <p>- listing 2</p>
              <p>- listing 3</p>
            </>
          }
          rightColumn={
            <ul className="ml-8">
              <li className="list-disc">
                <p>listing 1</p>
              </li>
              <li className="list-disc">
                <p>listing 2</p>
              </li>
              <li className="list-disc">
                <p>listing 3</p>
              </li>
            </ul>
          }
        />
      </div>
    </Dialog>
  );
};

export default React.memo(TipModal);
