import React, { useMemo } from 'react';
import uniqueId from 'lodash/uniqueId';
import spacing from '../themes/spacing';
import { StyledWrapper, StyledLabel } from './styles';

interface CheckboxProps {
  onChange: (e) => void;
  checked?: boolean;
  name: string;
  labelSpace?: keyof typeof spacing;
  disabled?: boolean;
  children: React.ReactNode;
}

const Checkbox = (props: CheckboxProps) => {
  const {
    onChange = () => {},
    checked = false,
    name,
    labelSpace = 'sm',
    disabled,
    children,
  } = props;

  const id = useMemo(() => uniqueId('prefix-'), []);
  return (
    <StyledWrapper>
      <input
        id={`${id}-${name}`}
        type="checkbox"
        name={name}
        checked={checked}
        onChange={onChange}
        data-testid={`checkbox-${name}`}
        disabled={disabled}
      />
      <StyledLabel
        htmlFor={`${id}-${name}`}
        marginLeft={spacing[labelSpace]}
        disabled={disabled}
      >
        {children}
      </StyledLabel>
    </StyledWrapper>
  );
};

export default Checkbox;
