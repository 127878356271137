import React, { MutableRefObject } from 'react';

interface ITextEditorContext {
  onEditorClickFromOutside: MutableRefObject<() => void>;
  focusTextEditorOnRender: boolean;
}

export const TextEditorContext = React.createContext<ITextEditorContext>({
  onEditorClickFromOutside: null,
  focusTextEditorOnRender: false,
});
