import React from 'react';
import Dialog from 'seedly-component-library/dialog';
import Button from 'seedly-component-library/button-new';
import { confirmable, createConfirmation } from 'react-confirm';

interface OwnProps {
  show: boolean;
  proceed: () => void;
  cancel: () => void;
  dismiss: () => void;
  options: any;
}

const ConfirmationModal = (props: OwnProps) => {
  const { show, proceed, dismiss, cancel, options } = props;
  const { title, content, okText, cancelText } = options;

  return (
    <Dialog
      isOpen={show}
      widthSize="sm"
      onCloseClick={dismiss}
      headerTitle={title}
      isMobileFullHeight={false}
    >
      <p>{content}</p>
      <div className="flex justify-end mt-4">
        <Button variant="outline" onClick={() => cancel()}>
          {cancelText}
        </Button>
        <Button onClick={() => proceed()} className="ml-2">
          {okText}
        </Button>
      </div>
    </Dialog>
  );
};

const ConfirmableDialog = confirmable(ConfirmationModal);
const ConfirmationModalWrapper = createConfirmation(ConfirmableDialog);

const Confirmation = options => {
  return ConfirmationModalWrapper({ options });
};

export default Confirmation;
