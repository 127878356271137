import styled from 'styled-components';
import { media } from 'theme';

export const EditorContainer = styled.div`
  position: relative;
  display: flex;
  box-sizing: border-box;
  width: ${props => props.width || '100%'};
  font-size: 14px;
  border: ${props => props.borderStyle};
  border-radius: 16px;

  &:focus {
    outline: none;
  }

  ::placeholder {
    color: #99a1b3;
  }

  .public-DraftStyleDefault-block {
    a {
      color: ${props => props.theme.colors.seedlyBlue};
      text-decoration: underline;

      &:active {
        color: #4a7bab;
      }

      &:focus {
        color: #7eadda;
        outline: 0; /* reset for :focus */
        cursor: pointer;
      }

      &:visited {
        color: #5e93c5;
        text-decoration: none;
      }
    }
  }

  .draftJsFocusPlugin__focused__3Mksn {
    cursor: default;
    border-radius: 2px;
    box-shadow: 0 0 0 3px #accef7;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 14px;
    font-weight: 400;
  }

  ${media.largeAndDown`
    font-size: 16px;
    width: ${props => props.width || '100%'};
  `}
`;

export const EditorBodyContainer = styled.div`
  width: 100%;

  .public-DraftEditorPlaceholder-root {
    color: #99a1b3;
    position: absolute;
    padding: ${props => props.placeHolderPadding};
  }

  .public-DraftEditor-content {
    height: ${props => props.height};
    min-height: ${props => props.minHeight};
    overflow: auto;
    overflow-wrap: break-word;
    word-break: break-word;
    padding: ${props => props.contentPadding};
    resize: none;
  }

  .public-DraftEditorPlaceholder-hasFocus {
    display: none;
  }

  .DraftEditorPlaceholder-hidden {
    display: none;
  }

  .public-DraftStyleDefault-ul,
  .public-DraftStyleDefault-ol {
    margin: 16px 0;
    margin-left: 18px;
    padding: 0;
  }

  .public-DraftStyleDefault-unorderedListItem {
    list-style-type: disc;
    position: relative;
  }

  .public-DraftStyleDefault-orderedListItem,
  .public-DraftStyleDefault-ol {
    list-style-type: decimal;
    position: relative;
  }
`;
