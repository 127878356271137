export const convertFileToBase64 = imageFile => {
  return new Promise(resolve => {
    let baseURL = '';
    // Make new FileReader
    const reader = new FileReader();

    // Convert the file to base64 text
    reader.readAsDataURL(imageFile);

    // on reader load something...
    reader.onload = () => {
      baseURL = reader.result;
      resolve(baseURL);
    };
  });
};

export const verifyFileSize = imageFile => {
  const fileSize = imageFile.size / 1024 / 1024;
  const isLessThan5MB = fileSize <= 5; // Limit to 5MB file size
  return isLessThan5MB;
};

export const formatBase64Picture = picture => {
  const BASE64_PREFIX = 'base64,';
  if (picture && picture.indexOf(BASE64_PREFIX) !== -1) {
    const base64Index = picture.indexOf(BASE64_PREFIX) + BASE64_PREFIX.length;
    return picture.substring(base64Index);
  }
  return null;
};
