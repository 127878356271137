import React from 'react';

export const PAGE_TYPE = {
  HOME: 'home',
  TRENDING: 'trending',
  ARTICLE: 'article',
  GROUP: 'group',
  DISCOVER_GROUP: 'discover-groups',
};

export type CommunityPageType =
  | 'home'
  | 'trending'
  | 'article'
  | 'group'
  | 'discover-groups';

interface ICommunityPageContext {
  pageType: CommunityPageType;
  setPageType: (params: CommunityPageType) => void;
  groupId?: number;
}

export const CommunityPageContext = React.createContext<ICommunityPageContext>({
  pageType: null,
  setPageType: () => {},
  groupId: null,
});
