import styled from 'styled-components';

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  input[type='checkbox'] {
    transform: scale(1.2);
  }
`;

export const StyledLabel = styled.label<{
  marginLeft: string;
  disabled: boolean;
}>`
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  margin-left: ${props => props.marginLeft};
  ${props => props.disabled && `color: ${props.theme.colors.neutral5};`}
`;
