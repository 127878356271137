import React, {
  useState,
  useRef,
  useContext,
  useEffect,
  useCallback,
} from 'react';
import { Info } from 'react-feather';
import createMarkdownShortcutsPlugin from 'draft-js-markdown-shortcuts-plugin';
import createImagePlugin from 'draft-js-image-plugin';
import Editor from '@draft-js-plugins/editor';
import createLinkifyPlugin from '@draft-js-plugins/linkify';
import { themes } from 'seedly-component-library/themes';
import TipModal from 'components/post/TipModal';
import * as S from './styles';
import { TextEditorContext } from './TextEditorContext';

const TextEditor = props => {
  const {
    onEditorClick = () => {},
    editorState,
    onChange = () => {},
    placeholder,
    height,
    minHeight,
    width,
    borderStyle = `1px solid ${themes.colors.neutral4}`,
    placeHolderPadding = `8px 16px`,
    contentPadding = `9.5px 16px`,
    haveCommentTips,
  } = props;

  const editorRef = useRef(null);

  const [isTipModalOpen, setTipModalOpen] = useState(false);

  const handleEditorClick = useCallback(() => {
    editorRef.current.focus();
    onEditorClick();
  }, [editorRef, onEditorClick]);

  const { onEditorClickFromOutside, focusTextEditorOnRender } = useContext(
    TextEditorContext,
  );

  useEffect(() => {
    if (onEditorClickFromOutside) {
      onEditorClickFromOutside.current = handleEditorClick;
    }
  }, [onEditorClickFromOutside, handleEditorClick]);

  useEffect(() => {
    if (focusTextEditorOnRender) {
      handleEditorClick();
    }
  }, [focusTextEditorOnRender]);

  const [{ plugins }] = useState(() => {
    const markdownPlugin = createMarkdownShortcutsPlugin();
    const imagePlugin = createImagePlugin();
    const linkifyPlugin = createLinkifyPlugin();
    return { plugins: [markdownPlugin, imagePlugin, linkifyPlugin] };
  });

  return (
    <S.EditorContainer borderStyle={borderStyle}>
      <S.EditorBodyContainer
        onClick={handleEditorClick}
        height={height}
        width={width}
        minHeight={minHeight}
        contentPadding={contentPadding}
        placeHolderPadding={placeHolderPadding}
        {...props}
      >
        <Editor
          ref={editorRef}
          editorState={editorState}
          onChange={onChange}
          placeholder={placeholder}
          plugins={plugins}
        />
        {haveCommentTips && (
          <>
            <div
              className="px-4 py-2 bg-blue-100 w-full cursor-pointer rounded-br-2xl rounded-bl-2xl"
              onClick={() => setTipModalOpen(true)}
            >
              <div className="flex items-center w-full justify-between">
                <p className="text-blue-500">Learn how to style your text</p>
                <Info className="text-blue-400" size={18} />
              </div>
            </div>
            <TipModal
              isOpen={isTipModalOpen}
              setTipModalOpen={setTipModalOpen}
            />
          </>
        )}
      </S.EditorBodyContainer>
    </S.EditorContainer>
  );
};

export default TextEditor;
